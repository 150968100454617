import React, { Component } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import "./App.css";

var currentBar = window.location.pathname;
var currentURL = currentBar.toLowerCase();

function App() {
  if (currentURL === "/w2g") {
    window.location.href = "https://w2g.tv/iob5252qw6bzmte3re";
  }

  if (currentURL === "/w2k") {
    window.location.href =
      "https://w2g.tv/de/room/?access_key=ldjtp2cuuavzte2l8igf8p";
  }

  if (currentURL === "/panel") {
    window.location.href =
      "https://v2202101136183139819.supersrv.de/login_up.php";
  }

  //Chrome Extension

  if (currentURL === "/ig") {
    window.location.href = "https://www.instagram.com/codesk.studio";
  }

  if (currentURL === "/e/whatfont") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/whatfont/jabopobgcpjmedljpbcaablpmlmfcogm";
  }

  if (currentURL === "/e/gofullpage") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/gofullpage-full-page-scre/fdpohaocaechififmbbbbbknoalclacl";
  }

  if (currentURL === "/e/lipsum") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/lipsum-generator/fepopmflofkppphpkfjdbmimglkeifna";
  }

  if (currentURL === "/e/speedtest") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/speedtest-by-ookla/pgjjikdiikihdfpoppgaidccahalehjh";
  }

  if (currentURL === "/e/colorzilla") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/colorzilla/bhlhnicpbhignbdhedgjhgdocnmhomnp";
  }

  if (currentURL === "/e/fontninja") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/fonts-ninja/eljapbgkmlngdpckoiiibecpemleclhh";
  }

  if (currentURL === "/e/csspeeper") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/css-peeper/mbnbehikldjhnfehhnaidhjhoofhpehk";
  }

  if (currentURL === "/e/csspeeper") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/css-peeper/mbnbehikldjhnfehhnaidhjhoofhpehk";
  }

  if (currentURL === "/e/unstack") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/unstack-style-guide/iabnnjcfonfnjhpmdgapgemfmgjlbjem/";
  }

  if (currentURL === "/e/savee") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/savee/hhefhkepfnmcgalemmofagaioeegonbc";
  }

  if (currentURL === "/e/10015") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/online-tools-by-10015io/afbphoagjpegnkpeiliacmiiggojdabo";
  }

  if (currentURL === "/e/pageruler") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/page-ruler/jcbmcnpepaddcedmjdcmhbekjhbfnlff";
  }

  if (currentURL === "/e/clearcache") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/clear-cache-for-chrome/lcebokhepdpopanpieoopnjiehmoabfp";
  }

  if (currentURL === "/e/save2notion") {
    window.location.href =
      "https://chrome.google.com/webstore/detail/save-to-notion/ldmmifpegigmeammaeckplhnjbbpccmm";
  }

  if (currentURL === "/e/add2calendar") {
    window.location.href = "https://hugolevet.fr/notion-add-to-calendar/";
  }

  // VS Code Extension

  if (currentURL === "/vs/bettercomments") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=aaron-bond.better-comments";
  }

  if (currentURL === "/vs/denigma") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=denigmaapp.denigma";
  }

  if (currentURL === "/vs/gitpack") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=donjayamanne.git-extension-pack";
  }

  if (currentURL === "/vs/ipsum") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=Tyriar.lorem-ipsum";
  }

  if (currentURL === "/vs/prettier") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode";
  }

  if (currentURL === "/vs/discordrpc") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=icrawl.discord-vscode";
  }

  if (currentURL === "/vs/discode") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=evenaven.discode";
  }

  if (currentURL === "/vs/vsdchat") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=karigari.chat";
  }

  if (currentURL === "/vs/dctools") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=Darkempire78.discord-tools";
  }

  if (currentURL === "/vs/blockman") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=leodevbro.blockman";
  }

  if (currentURL === "/vs/renametag") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-rename-tag";
  }

  if (currentURL === "/vs/projectmanager") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=alefragnani.project-manager";
  }

  if (currentURL === "/vs/remotehub") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=GitHub.remotehub";
  }

  if (currentURL === "/vs/importcost") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=wix.vscode-import-cost";
  }

  if (currentURL === "/vs/todo") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=fabiospampinato.vscode-todo-plust";
  }

  if (currentURL === "/vs/bookmarks") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=alefragnani.Bookmarks";
  }

  if (currentURL === "/vs/todotree") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=Gruntfuggly.todo-tree";
  }

  if (currentURL === "/vs/warmup") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=Jeusto.warm-up-typing-test";
  }
  if (currentURL === "/vs/codetime") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=softwaredotcom.swdc-vscode";
  }

  if (currentURL === "/vs/codelink") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=codelingo.codelingo-codelink";
  }

  if (currentURL === "/vs/snipped") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=JeffersonLicet.snipped";
  }

  if (currentURL === "/vs/emoji") {
    window.location.href =
      "https://marketplace.visualstudio.com/items?itemName=Perkovec.emoji";
  }

  //Github

  if (currentURL === "/gh/aboutme") {
    window.location.href =
      "https://share.streamlit.io/rahulbanerjee26/githubaboutmegenerator/main/__init__.py";
  }

  if (currentURL === "/gh/mg") {
    window.location.href =
      "https://rahuldkjain.github.io/gh-profile-readme-generator/";
  }

  if (currentURL === "/gh/ssgit") {
    window.location.href =
      "https://arturssmirnovs.github.io/github-profile-readme-generator/";
  }

  if (currentURL === "/gh/psdiscord") {
    window.location.href = "https://github.com/Tustin/PlayStationDiscord";
  }

  if (currentURL === "/gh/awbadges") {
    window.location.href = "https://envoy-vc.github.io/awesome-badges/";
  }

  if (currentURL === "/gh/mkbadges") {
    window.location.href = "https://naereen.github.io/badges/";
  }

  if (currentURL === "/gh/badgesgen") {
    window.location.href =
      "https://michaelcurrin.github.io/badge-generator/#/repo";
  }

  if (currentURL === "/gh/repobadges") {
    window.location.href = "https://github.com/dwyl/repo-badges";
  }

  if (currentURL === "/gh/badges4me") {
    window.location.href = "https://kapasia-dev-ed.my.site.com/Badges4Me/s/";
  }
  return (
    <div className="App center header">
      <TypeWriterEffect
        textStyle={{
          color: "#3F3D56",
          fontWeight: "normal",
          fontSize: "10rem",
        }}
        startDelay={100}
        cursorColor="#fff"
        text={currentBar}
        typeSpeed={150}
        hideCursorAfterText={true}
      />
    </div>
  );
}

export default App;
